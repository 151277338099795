import { useEffect } from 'react';

const useGTM = () => {
  useEffect(() => {
    const loadGTM = () => {
      if (!window.gtmDidInit) {
        const GTAG_ID = process.env.REACT_APP_ENV === 'prod' ? 'GTM-WP9Q2P' : 'GTM-T7CDXL2';
        console.log('GTM start loading');
        window.gtmDidInit = true;
        const gtagLink = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
        //const sriHash = await calculateSRIHash(gtagLink);
        const gtagScript = document.createElement('script');
        gtagScript.src = gtagLink;
        gtagScript.async = true;
        //gtagScript.integrity = sriHash;
    
        //gtagScript.crossOrigin = "anonymous";
        document.head.appendChild(gtagScript);
    
        const inlineGtagScript = document.createElement('script');
        inlineGtagScript.innerHTML = `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${GTAG_ID}",{page_path:window.location.pathname});`;
        document.head.appendChild(inlineGtagScript);
    
        const gtmScript = document.createElement('script');
        gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTAG_ID}');`;
        document.head.appendChild(gtmScript);

        gtagScript.onload = () => {
          console.log('GTM loaded successfully');
        };
      }
    };

    const onUserInteraction = () => {
      loadGTM();
      window.removeEventListener('scroll', onUserInteraction);
      window.removeEventListener('mousemove', onUserInteraction);
      window.removeEventListener('touchstart', onUserInteraction);
    };

    window.addEventListener('scroll', onUserInteraction);
    window.addEventListener('mousemove', onUserInteraction);
    window.addEventListener('touchstart', onUserInteraction);

    return () => {
      window.removeEventListener('scroll', onUserInteraction);
      window.removeEventListener('mousemove', onUserInteraction);
      window.removeEventListener('touchstart', onUserInteraction);
    };
  }, []);
};

export default useGTM;
