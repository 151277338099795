import Snackbar from '@material-ui/core/Snackbar';
import { arrayOf, node, oneOf, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Notification from './Notification';

let openSnackbarFn;

export function openSnackbar({ message, variant, duration }) {
  openSnackbarFn && openSnackbarFn({ message, variant, duration });
}

const Notifier = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(props.message);
  const [variant, setVariant] = useState(props.variant);
  const [duration, setDuration] = useState(3000);

  useEffect(() => {
    openSnackbarFn = openSnackbar;
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    {
      setOpen(false);
      setMessage('');
    }
  };
  
  const openSnackbar = ({ message, variant, duration }) => {
    setOpen(true);
    setMessage(message);
    setVariant(variant);
    setDuration(duration);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={duration || 3000}
      onClose={handleSnackbarClose}
      open={open}
      ContentProps={{ 'aria-describedby': 'snackbar-message-id' }}
    >
      <Notification
        onSnackbarClose={handleSnackbarClose}
        variant={variant}
        message={message}
        contentGrid={false}
        hiddenActionIcon={false}
      />
    </Snackbar>
  );
};

Notifier.propTypes = {
  message: oneOfType([arrayOf(node), node, string]),
  variant: oneOf(['error', 'info', 'success', 'warning']),
};

Notifier.defaultProps = {
  variant: 'info',
  message: '',
};

export const snackbar = {
  error: (message, duration) => openSnackbarFn && openSnackbarFn({ message, variant: 'error', duration }),
  success: (message, duration) => openSnackbarFn && openSnackbarFn({ message, variant: 'success', duration }),
  info: (message, duration) => openSnackbarFn && openSnackbarFn({ message, variant: 'info', duration }),
  warning: (message, duration) => openSnackbarFn && openSnackbarFn({ message, variant: 'warning', duration }),
};

export default Notifier;

